import React, { FC } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Grid, Button, Theme, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import langs, { __ } from '../langs'
import SEO from '../components/seo'
import Hero from '../components/hero'
import { Intro } from '../components/home/intro'
import { Reviews } from '../components/home/reviews'
import { WhatIsBlinkCart } from '../components/home/what-is-blinkcart'
import { FAQ } from '../components/home/faq'

import { GoogleSpeedInsights } from '../components/home/google-speed-insights'

import Header from '../layout/header'

const IndexPage: FC = () => {
  return (
    <>
      <SEO title={langs.home.meta_title} />
      <Box className={`section sectionHeader`}>
        <div id="">
          <Box className={`sectionInner`}>
            <Header addBg={false} />
            <Intro />
          </Box>
        </div>
      </Box>
      <Box className={`section`}>
        <Box className={`sectionInner`} style={{ maxWidth: '800px' }}>
          <WhatIsBlinkCart />
        </Box>
      </Box>

      <Box className={`section sectionBg`}>
        <Box className={`sectionInner`}>
          <GoogleSpeedInsights />
        </Box>
      </Box>
      <Box className={`section`}>
        <Box className={`sectionInner`}>
          <Reviews />
        </Box>
      </Box>

      {/* <Box className={`section`}>
        <Box className={`sectionInner`}>
          <HowMakeIntegration />
        </Box>
      </Box> */}

      {/* <Box className={`section`}>
        <Box className={`sectionInner`}>
          <PricingOptions />
        </Box>
      </Box> */}

      <Box className={`section sectionBg`}>
        <Box className={`sectionInner`}>
          <FAQ />
        </Box>
      </Box>
    </>
  )
}

export default IndexPage

import React from 'react'
import { Box, FormControl, Typography, Grid } from '@material-ui/core'
import langs, { __ } from '../../langs'

export const GoogleSpeedInsights = () => {
  return (
    <>
      <h2
        id="scores"
        dangerouslySetInnerHTML={{
          __html: langs.home.googleSpeedInsights.title,
        }}
      ></h2>

      <div id="tableContainer">
        <table width="100%">
          <tr>
            <td width="40%" className="empty"></td>
            <td width="30%">Magento default</td>
            <td width="30%">Magento with Blink-cart</td>
          </tr>

          <tr>
            <td> DOM content loaded</td>
            <td>~ 1 s</td>
            <td> 0.0 s</td>
          </tr>

          <tr>
            <td> Page loaded</td>
            <td>~ 2 s</td>
            <td>~ 0.2 s</td>
          </tr>

          <tr>
            <td>
              <strong> Developers.google.com </strong> <br />
              <span className="light">(PageSpeed Insights)</span>
            </td>
            <td>
              <div className="scores">
                <div className="score low">
                  <div className="circle">
                    <div className="radius p30"></div>
                    <div className="label">33%</div>
                  </div>
                  <p>Mobile</p>
                </div>

                <div className="score medium">
                  <div className="circle">
                    <div className="radius p50"></div>
                    <div className="label">53%</div>
                  </div>
                  <p>Desktop</p>
                </div>
              </div>
            </td>
            <td>
              <div className="scores">
                <div className="score high">
                  <div className="circle">
                    <div className="radius p85"></div>
                    <div className="label">90%</div>
                  </div>
                  <p>Mobile</p>
                </div>

                <div className="score high">
                  <div className="circle">
                    <div className="radius p100"></div>
                    <div className="label">99%</div>
                  </div>
                  <p>Desktop</p>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>Gtmetrix.com </td>
            <td>
              <div classNameName="score medium">
                <div classNameName="circle">
                  <div classNameName="radius p70"></div>
                  <div classNameName="label">77%</div>
                </div>
              </div>
            </td>
            <td>
              <div classNameName="score high">
                <div classNameName="circle">
                  <div classNameName="radius p100"></div>
                  <div classNameName="label">98%</div>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>Tools.pingdom.com </td>
            <td>
              <div classNameName="score high">
                <div classNameName="circle">
                  <div classNameName="radius p85"></div>
                  <div classNameName="label">82%</div>
                </div>
              </div>
            </td>
            <td>
              <div classNameName="score high">
                <div classNameName="circle">
                  <div classNameName="radius p85"></div>
                  <div classNameName="label">83%</div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </>
  )
}

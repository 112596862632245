import React from 'react'
import { lan } from '../../langs'
import { Link } from 'gatsby'

const pl = {
  title: 'Zmaksymalizuj zyski z Blink-Cart',
  description:
    'Blink-Cart to kompleksowe rozwiązania dla sklepów internetowych, które zwiększają konwersje i poprawiają doświadczenie użytkowników. Oferujemy pięć kluczowych usług, dostępnych zarówno indywidualnie, jak i w pakiecie, aby zapewnić najlepsze efekty synergii.',
  services: [
    {
      name: 'Blink-Frontend',
      description:
        'Nasza nakładka na sklep online sprawia, że Twój sklep wygląda nowocześnie i działa szybko, podobnie jak aplikacja mobilna. Dzięki niej strona ładuje się błyskawicznie, co sprawia, że zakupy są prostsze i przyjemniejsze dla użytkowników.',
      link: '/blink-frontend',
      link_anchor: 'Zalety Blink-Frontend',
    },
    {
      name: 'Blink-Search',
      description:
        'Zaawansowana wyszukiwarka zapewniająca precyzyjne i szybkie wyniki, wykorzystująca synonimy, filtry oraz ultra-szybką wydajność.',
      link: '/blink-search',
      link_anchor: 'Wyszukiwarka Blink-Search',
    },
    {
      name: 'Blink-Cookies',
      description:
        'Unikalne zarządzanie zgodami na pliki cookies, które zwiększa szybkość ładowania strony, wyróżnia się tym, że nie ładuje zewnętrznych skryptów do czasu ich akceptacji przez użytkownika, zamiast jedynie blokować pliki cookies. Dzięki temu znacząco poprawia wydajność działania sklepu.',
    },
    {
      name: 'Blink-Advisor',
      description:
        'System rekomendacji produktów oparty na AI, personalizujący ofertę dla klientów.',
    },
    {
      name: 'Blink-AB',
      description:
        'Narzędzie do testów A/B, które optymalizuje działania marketingowe i UX.',
    },
  ],
  cta:
    'Wybierz jedną z naszych usług lub skorzystaj ze wszystkich, aby maksymalizować zyski i efektywność swojego sklepu internetowego!',
}

const en = {
  title: 'Maximize Your Profits with Blink-Cart',
  description:
    'Blink-Cart offers a comprehensive solution for online stores, enhancing conversions and improving user experience. We provide five key services, available individually or as a bundle for the best synergy effects.',
  services: [
    {
      name: 'Blink-Frontend',
      description:
        'Our overlay for online stores makes your shop look modern and operate quickly, similar to a mobile app. It ensures that the page loads instantly, making shopping simpler and more enjoyable for users.',
      link: '/blink-frontend',
      link_anchor: 'What is Blink-Frontend',
    },
    {
      name: 'Blink-Search',
      description:
        'An advanced search engine that delivers precise and rapid results, utilizing synonyms, filters, and ultra-fast performance.',
      link: '/blink-search',
      link_anchor: 'What is Blink-Search',
    },
    {
      name: 'Blink-Cookies',
      description:
        "Unique cookie consent management that boosts page load speed stands out by not loading external scripts until they are accepted by the user, rather than just blocking cookies. This approach significantly enhances the store's performance.",
    },
    {
      name: 'Blink-Advisor',
      description:
        'An AI-based product recommendation system, personalizing offers for customers.',
    },
    {
      name: 'Blink-AB',
      description:
        'An A/B testing tool that optimizes marketing actions and UX.',
    },
  ],
  cta:
    'Choose one of our services or use all of them to maximize your profits and efficiency of your online store!',
}

const defs = { pl, en }

export const WhatIsBlinkCart = () => {
  const lanDef = defs[lan]

  return (
    <>
      <h2>{lanDef.title}</h2>
      <p>{lanDef.description}</p>
      <ul>
        {lanDef.services.map((service, index) => (
          <li key={index}>
            <strong>{service.name}:</strong> {service.description}
            {service.link && (
              <Link to={service.link}> {service.link_anchor}</Link>
            )}
          </li>
        ))}
      </ul>
    </>
  )
}

import React, { useState } from 'react'
import { Box, FormControl, Typography, Grid, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Api } from '../../services/api'
import { FieldWrapper } from '../hoc/FieldWrapper'
import { navigate } from 'gatsby'
import { ButtonWrapper } from '../hoc/ButtonWrraper'
import { ActionTypeTypes, useFormData } from '../../store/context'
import Alert from '@material-ui/lab/Alert'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import langs, { lan, __ } from '../../langs'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import Petteri from '../../../src/images/petteri.jpg'
import Artur from '../../../src/images/perek.jpg'

export const Reviews = () => {
  const getReviewImg = id => {
    if (id === 'petteri') {
      return Petteri
    } else if (id === 'artur') {
      return Artur
    }
  }
  return (
    <>
      <h2 id="case-studies">{langs.home.reviews.title}</h2>
      {langs.home.reviews.reviews.map((el, index) => (
        <div key={index} className="review">
          <div className="info">
            <div className="author">
              <img src={getReviewImg(el.id)} width="65" height="65" />
              <div className="name">
                <p> {el.author} </p>
                <p>
                  <a href={'https://' + el.url} rel="nofollow" target="_blank">
                    {el.url}
                  </a>
                </p>
              </div>
            </div>
            <div className="grade">
              <p className="note">+{el.conversion_value}%</p>
              <p className="label">{langs.home.reviews.conversion} </p>
            </div>
            <div className="grade">
              <p className="note">+{el.avg_value}%</p>
              <p className="label">{langs.home.reviews.avg}</p>
            </div>
          </div>
          <div>
            <div style={{ width: '40px', marginTop: '20px' }}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.0356445 0.800003V7.70842H2.99639C2.99639 8.81496 2.78705 9.75702 2.36836 10.5346C1.94966 11.2822 1.17209 11.8505 0.0356445 12.2393V15.2C0.962747 15.0804 1.81508 14.8112 2.59265 14.3925C3.40013 13.9439 4.08798 13.3907 4.65621 12.7327C5.22443 12.0748 5.65807 11.3122 5.95714 10.4449C6.28611 9.57757 6.43565 8.65047 6.40574 7.66355V0.800003H0.0356445ZM9.59079 0.800003V7.70842H12.5515C12.5515 8.81496 12.3422 9.75702 11.9235 10.5346C11.5048 11.2822 10.7272 11.8505 9.59079 12.2393V15.2C10.5179 15.0804 11.3702 14.8112 12.1478 14.3925C12.9553 13.9439 13.6431 13.3907 14.2113 12.7327C14.7796 12.0748 15.2132 11.3122 15.5123 10.4449C15.8413 9.57757 15.9908 8.65047 15.9609 7.66355V0.800003H9.59079Z"
                  fill="#2D3034"
                />
              </svg>
            </div>
          </div>
          <p dangerouslySetInnerHTML={{ __html: el.content }} />
        </div>
      ))}
    </>
  )
}

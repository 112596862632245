import React, { useState } from 'react'
import { Box, FormControl, Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Api } from '../../services/api'
import { FieldWrapper } from '../../components/hoc/FieldWrapper'
import { navigate, Link } from 'gatsby'
import { ButtonWrapper } from '../../components/hoc/ButtonWrraper'
import { ActionTypeTypes, useFormData } from '../../store/context'
import Alert from '@material-ui/lab/Alert'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import langs, { __ } from '../../langs'

const useStyles = makeStyles(theme => ({
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  select: {
    minWidth: 140,
  },
  selectEmpty: {},
  relative: {
    position: 'relative',
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },

  buttonProgress: {
    color: '#303f9f',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -12,
  },
}))

export const Intro = () => {
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useFormData()
  const [checkedTerms, setCheckedTerms] = React.useState(false)

  const classes = useStyles()

  const { email, url, software } = state

  const createHandleUpdate = function (actionType: any) {
    return function (value: unknown, valid: boolean) {
      if (dispatch) {
        dispatch({ type: actionType, payload: value })
      }
    }
  }

  const handleCheckTerms = (event: any) => {
    setCheckedTerms(event.target.checked)
  }

  const handleStartIntegration = async () => {
    if (checkedTerms === false) {
      setErrorMessage(langs.common.label_terms_error)
      return
    }
    setLoading(true)

    const response = await Api.testConnection({
      url: state.url.value,
      email: state.email.value,
      software: state.software.value,
    })

    setLoading(false)

    if ('error' in response) {
      setErrorMessage(response.error.message)
    } else {
      navigate('/setup')
    }
  }

  return (
    <>
      <Grid zeroMinWidth container className="intro">
        <Grid item xs={12} sm={7} className="slogan">
          <h1
            dangerouslySetInnerHTML={{
              __html: __(langs.home.intro.text_above),
            }}
          />
          <ul>
            {langs.home.intro.text_above_points.map((el, index) => (
              <li key={index}>{el}</li>
            ))}
          </ul>
        </Grid>
        <Grid item xs={12} sm={5} className="main-form">
          <div className="insde">
            <h4>{langs.home.intro.text_above_form}</h4>
            {errorMessage && (
              <Alert variant="outlined" severity="warning">
                {errorMessage}
              </Alert>
            )}
            <Grid
              zeroMinWidth
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12} sm={12}>
                <FormControl className="w100" variant="filled">
                  <FieldWrapper
                    field={email}
                    onChange={createHandleUpdate(ActionTypeTypes.UPDATE_EMAIL)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl className="w100" variant="filled">
                  <FieldWrapper
                    field={url}
                    onChange={createHandleUpdate(ActionTypeTypes.UPDATE_URL)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl
                  className="w100"
                  variant="filled"
                  className={[classes.select].join(' ')}
                >
                  <FieldWrapper
                    field={software}
                    onChange={createHandleUpdate(
                      ActionTypeTypes.UPDATE_SOFTWARE,
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              zeroMinWidth
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12} sm={12}>
                <div className="terms-conteinter">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedTerms}
                        onChange={handleCheckTerms}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="checkedI"
                      />
                    }
                    label={langs.common.label_terms}
                  />
                  <Link to="/terms">{langs.terms.title}</Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl
                  className={[
                    classes.select,
                    classes.relative,
                    classes.alignCenter,
                  ].join(' ')}
                >
                  <ButtonWrapper
                    size="large"
                    loading={loading}
                    onClick={handleStartIntegration}
                  >
                    {langs.home.intro.btn_submit}
                  </ButtonWrapper>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

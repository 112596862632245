import React, { useState } from 'react'
import { Box, FormControl, Typography, Grid } from '@material-ui/core'
import langs, { __ } from '../../langs'

export const FAQ = () => {
  const [open, setOpen] = useState([])

  const toogleOpen = (index: number) => {
    const newOpen = [...open]

    const findIndex = newOpen.findIndex(el => el === index)

    if (findIndex !== -1) {
      delete newOpen[findIndex]
    } else {
      newOpen.push(index)
    }

    setOpen(newOpen)
  }

  return (
    <>
      <div id="faq">
        <h2>{langs.home.faq.title}</h2>
        <ul className="faq">
          {langs.home.faq.faq.map((el, index) => (
            <li
              onClick={() => toogleOpen(index)}
              className={open.includes(index) ? 'active' : ''}
              key={index}
            >
              <h5>{el.title}</h5>
              <div dangerouslySetInnerHTML={{ __html: el.content }} />
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
